import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tile,
  Button,
  InlineNotification,
  Loading,
  Tabs,
  Tab,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
} from 'carbon-components-react';
import { withTranslation } from 'react-i18next';

import BackRow from 'components/common/BackRow/BackRow';

import {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
  createCalendarEvent,
  getAvailabilityElementToken,
  getStudentInterviewSlot,
  deleteCalendarEvent,
  getInstitutionFreeSlots,
} from 'actions/calendarEvents';
import { sendEmails } from 'actions/talentSearch/talentPool';

import {
  getStudentInterviewAssessments,
  addCandToAssessment,
  sendAssessInvitationEmail,
} from 'actions/studentAssessments';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';

class InterviewSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
      loadingSlots: true,
      slots: [],
      selectedSlot: 0,
      isScheduling: false,
      scheduledEvents: [],
      loadingEvents: true,
      selectedTab: 0,
      deletingEvent: false,
      slotsList: [],
      confirmingSlot: false,
      disableBooking: false,
      fetchingSlots: false,
    };
  }

  componentDidMount() {
    const { location: { state } } = this.props;
    const { selectedTab = 0 } = state || {};
    this.handleTabClick(selectedTab);
    this.getStudentInterviews();
  }

  handleOnChange = (name, v) => {
    this.setState({
      [`${name}`]: v,
    });
  };

  closeNotification = () => {
    this.setState({
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
    });
  };

  listSlots = (events) => {
    const { user } = this.props;
    const { companyId, tier } = user || {};
    this.props
      .listInstitutionSlots(companyId)
      .then((res) => {
        const { error, slots } = res || {};
        if (!res || error) {
          throw error;
        }
        const eventSlotsArr = events.map((e) => Number(e.institutionSlotId));
        let avSlots = slots.filter(
          (s) => !eventSlotsArr.includes(Number(s.slotId))
        );
        //do not show 11/06 to 11/08 slot for tier-5 candidates
        if (tier && tier == 5) {
          avSlots = avSlots.filter(
            (s) =>
              s.slotType == 'fixed' ||
              (s.slotType == 'flexible' &&
                s.interviewDates &&
                s.interviewDates.startDate &&
                s.interviewDates.startDate.indexOf('11/06') == -1)
          );
        }

        this.setState({
          slots: avSlots || [],
          loadingSlots: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSlots: false,
        });
      });
  };

  getStudentInterviews = () => {
    const { user } = this.props;
    const { profileId } = user || {};
    this.props
      .getStudentInterviewSlot(profileId)
      .then((res) => {
        const { events, videoInterview } = res || {};
        const { publicUuid } = videoInterview || {};
        const videoInterviewUrl = this.constructVideoInterviewURL(publicUuid);
        this.setState(
          {
            scheduledEvents: events || [],
            loadingEvents: false,
            videoInterview,
            videoInterviewUrl,
          },
          () => {
            this.listSlots(events);
          }
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loadingEvents: false,
        });
      });
  };

  scheduleEventSlot = async (slot, slotId, interviewName) => {
    const { t } = this.props;
    try {
      this.setState({
        isScheduling: true,
        [`be-${slotId}`]: true,
      });
      const data = {
        ...slot,
        institutionSlotId: slotId || 0,
        interviewName,
      };
      data.eventType='live-video-interview';
      // Creates an event in the connected calendar and in the
      // calendar events table
      const res = await this.props.createCalendarEvent(data);
      const { eventId, start, end, error } = res || {};
      if (eventId && start && end) {
        this.setState(
          {
            scheduledSlot: res,
            isScheduling: false,
            [`be-${slotId}`]: true,
            selectedSlot: 0,
            notifMessage: `${t('slotConfirmed')}`,
            notifShow: true,
            notifType: 'success',
            selectedTab: 1,
          },
          () => this.getStudentInterviews()
        );
      } else {
        this.setState({
          isScheduling: false,
          [`be-${slotId}`]: false,
          selectedSlot: 0,
          notifMessage: (error && error.message) || `${t('slotError')}`,
          notifShow: true,
          notifType: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        isScheduling: false,
        [`be-${slotId}`]: false,
        selectedSlot: 0,
        notifMessage: (error && error.message) || `${t('slotError')}`,
        notifShow: true,
        notifType: 'error',
      });
    }
  };

  wscroll = (h) => {
    window.scrollTo({
      top: h,
      behavior: 'smooth',
    });
  };

  confirmSlot = async (d, s) => {
    const { selectedSlotObj } = this.state;
    const { interviewName, slotId, duration } = selectedSlotObj || {};
    const startUTC = moment.utc(s, 'HH:mm').format();
    selectedSlotObj.start = startUTC;
    selectedSlotObj.end = moment.utc(startUTC).add(duration, 'm').format();
    selectedSlotObj.d = d;
    selectedSlotObj.s = s;
    this.setState(
      {
        confirmingSlot: true,
      },
      () => {
        this.scheduleEventSlot(selectedSlotObj, slotId, interviewName)
          .then(() => {
            this.setState(
              {
                confirmingSlot: false,
                selectedSlot: 0,
                [`be-${slotId}`]: false,
                slotsList: [],
                selectedSlotObj: {},
                disableBooking: false,
              },
              () => {
                this.wscroll(0);
              }
            );
          })
          .catch((err) => {
            console.error(err);
            this.setState(
              {
                confirmingSlot: false,
                selectedSlot: 0,
                [`be-${slotId}`]: false,
                slotsList: [],
                selectedSlotObj: {},
                disableBooking: false,
              },
              () => {
                this.wscroll(0);
              }
            );
          });
      }
    );
  };

  getDaysBetweenDates = (startDate, endDate) => {
    const dates = [startDate.format('DDMMYYYY')];
    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    let i = 0;
    while (currDate.add(1, 'd').diff(lastDate) <= 0) {
      dates.push(currDate.clone().format('DDMMYYYY'));
      if (i > 2) break;
      i++;
    }
    return dates;
  };

  getIntervals = (startTime, endTime, interval = 30) => {
    const intervals = [moment.utc(startTime, 'HH:mm').format('HHmm')];
    const currInterval = moment(startTime, 'HH:mm');
    const lastInterval = moment(endTime, 'HH:mm');
    let i = 0;
    while (currInterval.add(interval, 'm').diff(lastInterval) < 0) {
      intervals.push(currInterval.clone().format('HHmm'));
      if (i > 30) break; // JUST MAX CHECK
      i++;
    }
    return intervals;
  };

  tIntervals2 = (interviewDates, interval, bs) => {
    const { startDate, endDate, interviewStartTime, interviewEndTime } =
      interviewDates || {};
    const sd = moment(startDate, 'MM/DD/YYYY');
    const ed = moment(endDate, 'MM/DD/YYYY');
    const ldays = moment(ed).diff(sd, 'd');
    const fres = {};
    console.log('DDD', ldays);
    const datesArr = this.getDaysBetweenDates(sd, ed);
    console.log('datesArr', datesArr);
    const intervals = this.getIntervals(
      interviewStartTime,
      interviewEndTime,
      interval
    );
    console.log('intervalsintervals', intervals, intervals.length);
    const intervalsWithoutBreaks = intervals.filter((i) => {
      if (
        (i >= 1030 && i < 1100) ||
        (i >= 1230 && i < 1400) ||
        (i >= 1530 && i < 1600)
      ) {
        // break times
        return false;
      }
      if (i >= 900 && i < 1830) {
        return true;
      }
      return false;
    });
    console.log('intervalsWithoutBreaks', intervalsWithoutBreaks, datesArr);
    datesArr.forEach((cd) => {
      const avSlots = intervalsWithoutBreaks.filter((s) => {
        if (
          !bs.includes(
            moment.utc(`${cd}${s}`, 'DDMMYYYYHHmm').format('DDMMYYYYHHmm') + ''
          )
        ) {
          if (cd == "17052023") {
            console.log("MAY 17", cd);
            if (Number(s) >= 1300) {
              // iv ends
              return false;
            }
          }
          return true;
        }
        return false;
      });
      fres[`${cd}`] = avSlots;
    });
    console.log('d', fres);
    return fres;
  };

  getAvailabilitySlots = (slot) => {
    const { user, t } = this.props;
    const { companyId: studentInstitutionId } = user || {};
    const {
      duration,
      slotId,
      interviewName,
      slotType,
      interviewsPerSlot,
      startTime,
      endTime,
      interviewDates,
    } = slot || {};
    this.setState(
      {
        selectedSlot: slotId || 0,
        selectedSlotObj: slot || {},
        [`be-${slotId}`]: true,
        slotsList: [],
        disableBooking: true,
        fetchingSlots: true,
      },
      () => {
        console.log('SLOT', slot, interviewsPerSlot);
        this.props
          .getInstitutionFreeSlots(slotId, studentInstitutionId)
          .then((res) => {
            if (!res) {
              throw Error('Error occured while free slots');
            }
            if (res && this.state.disableBooking) {
              const bookedOnes = [];
              console.log('res', res);
              Object.keys(res).forEach((e) => {
                const d = res[e];
                if (d >= interviewsPerSlot) {
                  bookedOnes.push(e);
                }
              });
              const slotsArr = this.tIntervals2(
                interviewDates,
                duration || 30,
                bookedOnes
              );
              console.log('slotsArrslotsArrslotsArr', slotsArr);
              this.setState(
                {
                  slotsList: slotsArr,
                  fetchingSlots: false,
                },
                () => {
                  this.wscroll(window.screen.availHeight);
                }
              );
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState(
              {
                selectedSlot: 0,
                [`be-${slotId}`]: false,
                slotsList: [],
                selectedSlotObj: {},
                disableBooking: false,
                notifMessage: `${t('slotError')}`,
                notifShow: true,
                notifType: 'error',
                fetchingSlots: false,
              },
              () => {
                this.wscroll(0);
              }
            );
          });
      }
    );
  };

  cancelBooking = (slotId) => {
    this.setState({
      selectedSlot: 0,
      [`be-${slotId}`]: false,
      slotsList: [],
      selectedSlotObj: {},
      disableBooking: false,
    });
  };

  renderSlotsList = () => {
    const { slots, disableBooking } = this.state;
    const { t } = this.props;
    if (slots && Array.isArray(slots)) {
      let showFixed = true;
      const d = slots.filter((e) => e.slotType === 'fixed');
      if (d.length < 2) {
        showFixed = false;
      }
      const slotsList = slots.map((s) => {
        const {
          slotType,
          duration,
          startTime,
          endTime,
          slotId,
          interviewDescription,
        } = s || {};
        let { interviewName } = s || {};
        interviewName = interviewName && interviewName.toString() && interviewName.toString().includes('Live Video') ? interviewName.replace('Live Video', 'Face-to-Face') : interviewName;
        const isBooking = this.state[`be-${slotId}`];
        return (
          <div key={slotId}>
            {((slotType === 'fixed' && showFixed) ||
              slotType === 'flexible') && (
                <div className="bx--tile mb-2">
                  <div>
                    <div className="bx--row">
                      <div className="bx--col-md-2 text-secondary bx--type-strong">
                        {t('interview')} {t('name')}
                      </div>
                      <div>
                        :&nbsp;
                        <span className="bx--type-caps bx--type-strong">
                          {interviewName}
                        </span>
                      </div>
                    </div>
                    <div className="bx--row">
                      {slotType === 'fixed' && (
                        <Fragment>
                          <div className="bx--col-md-2 text-secondary bx--type-strong">
                            {t('interview')} {t('date')}
                          </div>
                          <div>
                            :{' '}
                            {moment(startTime).utc()
                              // .add('480', 'minutes')
                              .format('Do MMM YYYY')}
                          </div>
                        </Fragment>
                      )}
                      {slotType !== 'fixed' && (
                        <Fragment>
                          <div className="bx--col-md-2 text-secondary bx--type-strong">
                            {t('interview')} {t('dates')}
                          </div>
                          <div>
                            :{' '}
                            {moment(startTime).utc()
                              // .add('480', 'minutes')
                              .format('Do MMM YYYY')}{' '}
                            &nbsp; - &nbsp;
                            {moment(endTime).utc()
                              // .add('480', 'minutes')
                              .format('Do MMM YYYY')}
                          </div>
                        </Fragment>
                      )}
                    </div>
                    {slotType === 'fixed' && (
                      <Fragment>
                        <div className="bx--row">
                          <div className="bx--col-md-2 text-secondary bx--type-strong">
                            {t('start')} {t('time')}
                          </div>
                          <div>
                            :{' '}
                            {moment(startTime).utc()
                              // .add('480', 'minutes')
                              .format('HH:mm')}{' '}
                            (SGT)
                          </div>
                        </div>
                        <div className="bx--row">
                          <div className="bx--col-md-2 text-secondary bx--type-strong">
                            {t('end')} {t('time')}
                          </div>
                          <div>
                            :{' '}
                            {moment(endTime).utc()
                              // .add('480', 'minutes')
                              .format('HH:mm')}{' '}
                            (SGT)
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="bx--row">
                      <div className="bx--col-md-2 text-secondary bx--type-strong">
                        {t('duration')}
                      </div>
                      <div>: {duration} mins</div>
                    </div>
                    <div className="bx--row">
                      <div className="bx--col-md-2 text-secondary bx--type-strong">
                        {t('desc')}
                      </div>
                      <div>{interviewDescription || 'N/A'}</div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {slotType === 'fixed' && (
                      <Button
                        small
                        onClick={() => {
                          this.scheduleEventSlot(
                            { ...s, start: startTime, end: endTime },
                            slotId,
                            interviewName
                          );
                        }}
                        disabled={isBooking || disableBooking}>
                        {isBooking ? 'Booking...' : t('book')}
                      </Button>
                    )}
                    {slotType === 'flexible' && (
                      <Button
                        small
                        onClick={() => {
                          this.getAvailabilitySlots(s);
                        }}
                        disabled={isBooking || disableBooking}>
                        {isBooking ? 'Getting available slots...' : t('book')}
                      </Button>
                    )}
                    {disableBooking && isBooking && (
                      <Button
                        small
                        kind="tertiary"
                        onClick={() => {
                          this.cancelBooking(slotId);
                        }}
                        className="ml-2">
                        {t('cancel')}
                      </Button>
                    )}
                  </div>
                </div>
              )}
          </div>
        );
      });
      return slotsList;
    }
    return <div />;
  };

  getStudentInterviewAssessments = () => {
    const { user: { profileId } = {} } = this.props;
    this.props.getStudentInterviewAssessments(profileId);
  }

  handleTabClick = (tab) => {
    if (tab === 'video-assessments' || tab === 0) {
      this.getStudentInterviewAssessments();
      this.setState({
        selectedTab: 0,
      });
    } else if (tab === 'book-slots' || tab === 1) {
      this.setState({
        selectedTab: 1,
      });
    } else if (tab === 'scheduled-interviews' || tab === 2) {
      this.setState({
        selectedTab: 2,
      });
    }
  };

  renderBookSlots = () => {
    const {
      loadingSlots,
      slots,
      isScheduling,
      slotsList,
      confirmingSlot,
      disableBooking,
      selectedSlotObj,
      fetchingSlots,
    } = this.state;
    const { t } = this.props;
    let { duration, interviewName } = selectedSlotObj || {};
    interviewName = interviewName && interviewName.toString() && interviewName.toString().includes('Live Video') ? interviewName.replace('Live Video', 'Face-to-Face') : interviewName;

    return (
      <Fragment>
        {loadingSlots && <Loading withOverlay={false} />}
        <div className="d-flex flex-column">{this.renderSlotsList()}</div>
        {!isScheduling && slots && slots.length === 0 && (
          <div className="bx--type-gamma">{t('noInterviewsBook')}</div>
        )}
        <div className="mt-4">
          {/* {
            (selectedSlot > 0) && !isScheduling && slots && Array.isArray(slots) && slots.map((s) => {
              const { slotId } = s || {};
              return (
                <div
                  key={slotId}
                  id={`cronofy-slot-viewer-${slotId || 0}`}
                  className={(selectedSlot === slotId) ? 'cal-cronofy-slots' : 'hidden'}
                >
                  <div className="d-flex flex-column align-items-center">
                    <Loading withOverlay={false} />
                    <p>{t('loadingSlots')}</p>
                  </div>
                </div>
              );
            })
          } */}
          {disableBooking && slotsList && (
            <Fragment>
              <div className="bx--type-caps bx--type-epsilon mb-2 mt-2">
                Booking a slot for: &nbsp;
                {interviewName || 'INTERVIEW NAME'}
              </div>
              {fetchingSlots && !confirmingSlot && (
                <div className="d-flex flex-column align-items-center">
                  <Loading withOverlay={false} />
                  <p>Fetching slots...</p>
                </div>
              )}
            </Fragment>
          )}
          <div className="bx--row m-0">
            {disableBooking &&
              slotsList &&
              !confirmingSlot &&
              Object.keys(slotsList).map((d) => {
                const odArr = slotsList[d];
                return (
                  <div
                    key={d}
                    className="bx--row align-items-center bx--tile border mt-2 pb-2 ml-0 mr-0">
                    <div className="bx--col-md-12 bx--type-caps bx--type-epsilon m-2 text-center">
                      {moment(d, 'DDMMYYYY').format('Do MMMM YYYY')}
                    </div>
                    {odArr &&
                      Array.isArray(odArr) &&
                      odArr.map((s) => {
                        const sti = moment(s, 'HHmm').format('HH:mm');
                        const eti = moment(s, 'HHmm')
                          .add(duration || 30, 'm')
                          .format('HH:mm');
                        console.log('S', sti, eti, s);
                        return (
                          <Button
                            key={`${d}-${s}`}
                            className="p-2 border m-1"
                            onClick={() => {
                              console.log('S', sti, eti, s);
                              this.confirmSlot(d, s);
                            }}
                            kind="tertiary">
                            {sti} - {eti}
                          </Button>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          {isScheduling && (
            <div className="d-flex flex-column align-items-center">
              <Loading withOverlay={false} />
              <p>{t('confirmSlots')}</p>
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  handleDeleteScheduledEvent = (eventId) => {
    this.setState({
      [`de-${eventId}`]: true,
    });
    this.props
      .deleteCalendarEvent(eventId)
      .then((res) => {
        const { count, error } = res || {};
        if (!res || error) {
          this.setState({
            notifShow: true,
            notifMessage: 'Error occured while deleting the interview slot',
            notifType: 'error',
            [`de-${eventId}`]: false,
          });
        }
        if (count) {
          this.setState({
            notifShow: true,
            notifMessage: 'Interview slot deleted successfully',
            notifType: 'success',
            [`de-${eventId}`]: false,
          });
          this.getStudentInterviews();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while deleting the interview slot',
          notifType: 'error',
          [`de-${eventId}`]: false,
        });
      });
  };

  renderScheduledInterviews = () => {
    const { scheduledEvents, loadingEvents } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        {/* <div className="bx--type-epsilon">Scheduled Interviews:</div> */}
        {loadingEvents && (
          <div className="bx--tile d-flex flex-column">
            <Loading withOverlay={false} />
            <p>{t('slotLoading')}</p>
          </div>
        )}
        {scheduledEvents &&
          Array.isArray(scheduledEvents) &&
          scheduledEvents.map((se) => {
            let { eventId, summary, start, end, institutionslots, status, eventMeta } =
              se || {};
            const { interviewDescription, slotType } = institutionslots || {};
            const { zoom } = eventMeta || {};
            const { joinUrl } = zoom || {};
            const deletingEvent = this.state[`de-${eventId}`];
            let statusClass = 'border-bottom-dark';
            if (status === 'accepted') {
              statusClass = 'border-bottom-success';
            }
            if (status === 'rejected') {
              statusClass = 'border-bottom-danger';
            }
            let startTime = moment.utc(start).format('DD MMM YYYY HH:mm');
            let endTime = moment.utc(start).add(30, 'm').format('DD MMM YYYY HH:mm');
            if (slotType === 'fixed') {
              startTime = moment.utc(start).add(480, 'm').format('DD MMM YYYY HH:mm');
              endTime = moment.utc(start).add(540, 'm').format('DD MMM YYYY HH:mm');
            }
            summary = summary && summary.toString() && summary.toString().includes('Live Video') ? summary.replace('Live Video', 'Face-to-Face') : summary;
            return (
              <div
                key={eventId}
                className={`bx--tile border mt-2 p-4 ${statusClass}`}>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-secondary bx--type-strong">{`${t(
                    'interview'
                  )}`}</div>
                  <div>
                    :&nbsp;
                    <span className="bx--type-strong">
                      {summary || `${t('interview')}`}
                    </span>
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-secondary bx--type-strong">
                    {t('start')}
                  </div>
                  <div>: {startTime}</div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-secondary bx--type-strong">
                    {t('end')}
                  </div>
                  <div>
                    :{' '}
                    {endTime}
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-secondary bx--type-strong">
                    {t('status')}
                  </div>
                  <div>
                    :{' '}
                    <span className="bx--tag bx--tag--cyan bx--type-caps">
                      {status || 'Pending'}
                    </span>
                  </div>
                </div>
                <div className="text-secondary bx--type-strong">
                  {t('desc')}
                </div>
                <div> {interviewDescription || 'N/A'}</div>
                {joinUrl && (
                  <div>
                    <a href={joinUrl} target="_blank" rel="noopener noreferrer">Zoom Meeting Join URL</a>
                  </div>
                )}
                {(!status ||
                  !(
                    status &&
                    (status === 'accepted' || status === 'rejected')
                  )) && (
                    <Button
                      kind="danger"
                      onClick={() => this.handleDeleteScheduledEvent(eventId)}
                      small
                      className="mt-2"
                      disabled={deletingEvent}>
                      {deletingEvent ? 'Deleting...' : 'Delete'}
                    </Button>
                  )}
              </div>
            );
          })}
        {!loadingEvents &&
          scheduledEvents &&
          Array.isArray(scheduledEvents) &&
          scheduledEvents.length === 0 && (
            <div className="bx--tile">
              <div className="bx--type-gamma">{t('noInterviewSch')}</div>
            </div>
          )}
      </Fragment>
    );
  };

  constructVideoInterviewURL = (publicUuid) => {
    const { user } = this.props;
    const { email } = user || {};
    let recLink = '';
    const currEnv = window.location.host;
    if (currEnv === 'cstage.x0pa.ai') {
      recLink = 'https://demo.recright.com/interview/public/';
    } else if (currEnv === 'geip.x0pa.ai') {
      recLink = 'https://www.recright.com/interview/public/';
    }
    const videoUrl = publicUuid
      ? `${recLink}${publicUuid}?email=${email}`
      : null;
    return videoUrl;
  };

  sendVideoInterviewEmail = () => {
    const { user } = this.props;
    const { email, firstname, lastname, profileId } = user || {};
    const finalData = {
      templateId: 1020,
      templateName: 'video-interview-template-449-1',
      emailData: [
        {
          email,
          profileId,
          payload: {
            studentFirstName: firstname,
            studentLastName: lastname,
          },
        },
      ],
      actionName: 'user-defined-email',
    };
    this.setState(
      {
        sendingEmail: true,
      },
      () => {
        this.props
          .sendEmails(finalData)
          .then((r) => {
            if (r && !r.error) {
              const { publicUuid } = r || {};
              const videoUrl = this.constructVideoInterviewURL(publicUuid);
              this.setState({
                videoInterviewUrl: videoUrl,
                sendingEmail: false,
              });
            } else {
              this.setState({
                sendingEmail: false,
              });
            }
          })
          .catch((e) => {
            console.error(e);
            this.setState({
              sendingEmail: false,
            });
          });
      }
    );
  };

  sendAssessInvitationEmail = (videointerviewGroupId, interviewId) => {
    const { user: { profileId } } = this.props;
    const data = { videointerviewGroupId, interviewIds: [interviewId] }
    const payload = { data, sid: profileId }
    this.props.sendAssessInvitationEmail(payload).then((res) => {
      if (res && !res.error) {
        this.setState({
          notifShow: true,
          notifMessage: 'Invitation email has been sent successfully!',
          notifType: 'success',
        });
        this.getStudentInterviewAssessments();
      } else {
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while sending invitation email!',
          notifType: 'error',
        });
      }
    }).catch((err) => {
      console.error(err);
      this.setState({
        notifShow: true,
        notifMessage: 'Error occured while sending invitation email!',
        notifType: 'error',
      });
    })
  }

  signupCandForAssessment = (videointerviewGroupId) => {
    const { user: { firstname, lastname, email, profileId } } = this.props;
    const data = [{ firstName: firstname, lastName: lastname, emailAddress: email, videointerviewGroupId }];
    const payload = { data, sid: profileId }
    this.props.addCandToAssessment(payload).then((res) => {
      const { response } = res || {};
      const { interviewId, error } = (response && response[0]) || [];
      if (res && !error) {
        this.sendAssessInvitationEmail(videointerviewGroupId, interviewId);
      } else {
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while Signing Up for the assessment',
          notifType: 'error',
        });
      }
    }).catch((err) => {
      console.error(err);
      this.setState({
        notifShow: true,
        notifMessage: 'Error occured while Signing Up for the assessment',
        notifType: 'error',
      });
    })
  }

  render() {
    const { t, loadingInterviews, interviewAssessments, user } = this.props;
    const { email } = user || {};
    const paths = [
      { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
      { id: 'student-interview-slots', name: 'Interviews', href: '' },
    ];
    const {
      notifType,
      notifMessage,
      notifShow,
      selectedTab,
      slots,
      videoInterview,
      videoInterviewUrl,
      sendingEmail,
    } = this.state;
    const { rrCandidateId } = videoInterview || {};
    return (
      <Fragment>
        <BackRow paths={paths} />
        {notifShow && (
          <InlineNotification
            lowContrast
            kind={notifType}
            title={notifMessage}
            subtitle=""
            onCloseButtonClick={this.closeNotification}
          />
        )}
        <Tabs selected={selectedTab}>
          <Tab
            label={<span>Video Assessments</span>}
            onClick={() => this.handleTabClick('video-assessments')}>
            <div className="mt-2">
              {interviewAssessments && Array.isArray(interviewAssessments) && interviewAssessments.length > 0 ? (
                <div className="">
                  <StructuredListWrapper ariaLabel="Structured list">
                    <StructuredListHead>
                      <StructuredListRow
                        head
                        tabIndex={0}
                      >
                        <StructuredListCell head className="align-baseline">
                          Assessment Name
                        </StructuredListCell>
                        <StructuredListCell head className="align-baseline">
                          Action
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                      {interviewAssessments.map((assess) => {
                        const { videointerviewGroupId, groupName, candidateInfo, isAddedToAssessment } = assess || {};
                        const { interviewId } = candidateInfo || {};
                        return (
                          <StructuredListRow head tabIndex={0}>
                            <StructuredListCell>
                              <div className="">{groupName}</div>
                            </StructuredListCell>
                            <StructuredListCell>
                              {isAddedToAssessment ?
                                <div className="xpa-link"
                                  onClick={() => this.sendAssessInvitationEmail(videointerviewGroupId, interviewId)}>
                                  Resend Invitation Email
                                </div> :
                                <div className="">
                                  <Button
                                    small
                                    onClick={() => this.signupCandForAssessment(videointerviewGroupId)}
                                  >
                                    Signup for Assessment
                                  </Button>
                                </div>
                              }
                            </StructuredListCell>
                          </StructuredListRow>
                        )
                      })}
                    </StructuredListBody>
                  </StructuredListWrapper>
                </div>
              ) : (
                <div className="bx--tile">
                  <div className="bx--type-gamma">No Assessments Available</div>
                </div>
              )}
            </div>
          </Tab>
          <Tab
            label={<span>{t('interviewsBook')}</span>}
            onClick={() => this.handleTabClick('book-slots')}>
            {slots && Array.isArray(slots) && slots.length > 0 && (
              <Tile className="mt-2 mb-2">
                <div>
                  {/* <p className="bx--type-zeta mt-2 mb-0 text-primary">{t('slotMsg4')}</p> */}
                  <p className="mt-2">
                    {t('slotMsg5')} <b>askGIA@np.edu.sg</b> {t('slotMsg6')}
                  </p>
                </div>
              </Tile>
            )}
            {/* {email && !email.includes('np.edu.sg') ? (<div className="mt-2 mb-2 bx--tile">
              <h5>Profiling Assessment</h5>
              <p className="mt-2 mb-0">
                For the profiling assessment, you may choose to take it at any time by using the following link.
                You are reminded to use only your <b>school email</b> upon registering for the assessment:
              </p>
              <p>
                <a href="https://app.clevry.com/campaignsignup.aspx?campaigncode=s4o9z7h6" target="_blank" rel="noopener noreferrer">Link to Profiling assessment</a>
              </p>
              <p className="font-weight-bold text-danger">You need to finish the profiling assessment by 17th Nov 2022.</p>
            </div>) : (<div></div>)} */}

            {!rrCandidateId && false &&  (
              <div className="mt-2 mb-2 bx--tile">
                <h5>Video Interview</h5>
                <div>
                  Please answer the questions via a recorded video interview.
                </div>
                {videoInterviewUrl && (
                  <Button size="small" href={videoInterviewUrl} target="_blank">
                    Submit Answers
                  </Button>
                )}
                {!videoInterviewUrl && (
                  <div className="mt-2 mb-2">
                    <div className="mb-2">
                      Please click on the below button to receive interview url
                    </div>
                    <Button
                      size="small"
                      disabled={sendingEmail}
                      onClick={this.sendVideoInterviewEmail}>
                      {!sendingEmail
                        ? 'Get Interview URL'
                        : 'Fetching Interview URL'}
                    </Button>
                  </div>
                )}
              </div>
            )}
            {this.renderBookSlots()}
          </Tab>
          <Tab
            label={<span>{t('scheduleInterview')}</span>}
            onClick={() => this.handleTabClick('scheduled-interviews')}>
            <div className="mt-2">{this.renderScheduledInterviews()}</div>
          </Tab>
        </Tabs>
        {loadingInterviews &&
          <JDProcessOverlay show={loadingInterviews} message="processing..." />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  loadingInterviews: state.StudentAssessments.loading,
  interviewAssessments: state.StudentAssessments.assessments,
});

const mapDispatchToProps = {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
  createCalendarEvent,
  getAvailabilityElementToken,
  getStudentInterviewSlot,
  deleteCalendarEvent,
  getInstitutionFreeSlots,
  sendEmails,
  getStudentInterviewAssessments,
  addCandToAssessment,
  sendAssessInvitationEmail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InterviewSlots));
