import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button, InlineNotification, Loading,
} from 'carbon-components-react';
import { withTranslation } from 'react-i18next';

import EmailTemplatesModal from 'containers/roboroy/email-templates/EmailTemplatesModal';

import {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
  createCalendarEvent,
  getAvailabilityElementToken,
  getStudentInterviewSlot,
  deleteCalendarEvent,
  rejectCalendarEvent,
  acceptCalendarEvent,
} from 'actions/calendarEvents';
import { sendEmails } from 'actions/talentSearch/talentPool';

class ScheduledInterviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
      loadingSlots: true,
      slots: [],
      selectedSlot: 0,
      isScheduling: false,
      scheduledEvents: [],
      loadingEvents: true,
      selectedTab: 0,
      deletingEvent: false,
      isEmailModelOpen: false,
    };
  }

  componentDidMount() {
    this.getStudentInterviews();
  }

  handleOnChange = (name, v) => {
    this.setState({
      [`${name}`]: v,
    });
  }

  closeNotification = () => {
    this.setState({
      notifMessage: '',
      notifShow: false,
      notifType: 'success',
    });
  }

  listSlots = (events) => {
    const { user } = this.props;
    const { companyId } = user || {};
    this.props.listInstitutionSlots(companyId)
      .then((res) => {
        const { error, slots } = res || {};
        if (!res || error) {
          throw error;
        }
        const eventSlotsArr = events.map(e => Number(e.institutionSlotId));
        const avSlots = slots.filter(s => !eventSlotsArr.includes(Number(s.slotId)));
        this.setState({
          slots: avSlots || [],
          loadingSlots: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSlots: false,
        });
      });
  }

  getStudentInterviews = () => {
    const profileId = this.props.sid;
    this.props.getStudentInterviewSlot(profileId)
      .then((res) => {
        const { events } = res || {};
        this.setState({
          scheduledEvents: events || [],
          loadingEvents: false,
        }, () => {
          this.listSlots(events);
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loadingEvents: false,
        });
      });
  }

  handleAcceptScheduledEvent = (eventId) => {
    this.setState({
      [`ae-${eventId}`]: true,
    });
    this.props.acceptCalendarEvent(eventId)
      .then((res) => {
        const { studentId, error } = res || {};
        if (!res || error) {
          this.setState({
            notifShow: true,
            notifMessage: 'Error occured while accepting the student interview slot',
            notifType: 'error',
            [`ae-${eventId}`]: false,
          });
        }
        if (studentId) {
          this.setState({
            notifShow: true,
            notifMessage: 'Student interview slot accepted successfully',
            notifType: 'success',
            [`ae-${eventId}`]: false,
          });
          this.getStudentInterviews();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while accepting the student interview slot',
          notifType: 'error',
          [`ae-${eventId}`]: false,
        });
      });
  }

  handleRejectScheduledEvent = (eventId) => {
    this.setState({
      [`re-${eventId}`]: true,
    });
    this.props.rejectCalendarEvent(eventId)
      .then((res) => {
        const { studentId, error } = res || {};
        if (!res || error) {
          this.setState({
            notifShow: true,
            notifMessage: 'Error occured while rejecting the student interview slot',
            notifType: 'error',
            [`re-${eventId}`]: false,
          });
        }
        if (studentId) {
          this.setState({
            notifShow: true,
            notifMessage: 'Student interview slot rejected successfully',
            notifType: 'success',
            [`re-${eventId}`]: false,
          });
          this.getStudentInterviews();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while rejecting the student interview slot',
          notifType: 'error',
          [`re-${eventId}`]: false,
        });
      });
  }

  handleDeleteScheduledEvent = (eventId) => {
    this.setState({
      [`de-${eventId}`]: true,
    });
    this.props.deleteCalendarEvent(eventId)
      .then((res) => {
        const { count, error } = res || {};
        if (!res || error) {
          this.setState({
            notifShow: true,
            notifMessage: 'Error occured while deleting the interview slot',
            notifType: 'error',
            [`de-${eventId}`]: false,
          });
        }
        if (count === 0) {
          this.setState({
            notifShow: true,
            notifMessage: 'Unable to delete the selected interview slot',
            notifType: 'error',
            [`de-${eventId}`]: false,
          });
          this.getStudentInterviews();
        }
        if (count) {
          this.setState({
            notifShow: true,
            notifMessage: 'Interview slot deleted successfully',
            notifType: 'success',
            [`de-${eventId}`]: false,
          });
          this.getStudentInterviews();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          notifShow: true,
          notifMessage: 'Error occured while deleting the interview slot',
          notifType: 'error',
          [`de-${eventId}`]: false,
        });
      });
  }

  openEmailModal = (event) => {
    this.setState({
      isEmailModelOpen: true,
      selectedEvent: event,
    });
  }

  renderScheduledInterviews = () => {
    const {
      scheduledEvents,
      loadingEvents,
    } = this.state;

    const {
      t,
    } = this.props;
    return (
      <Fragment>
        {
          loadingEvents && (
            <div className="bx--tile d-flex flex-column">
              <Loading withOverlay={false} />
              <p>{t('slotLoading')}</p>
            </div>
          )
        }
        {
          scheduledEvents && Array.isArray(scheduledEvents) && scheduledEvents.map((se) => {
            const {
              eventId, summary, start, end,
              institutionslots, description,
              status,
            } = se || {};
            const { interviewDescription, slotType } = institutionslots || {};
            const acceptingEvent = this.state[`ae-${eventId}`];
            const rejectingEvent = this.state[`re-${eventId}`];
            const deletingEvent = this.state[`de-${eventId}`];
            const sendingEventEmail = this.state[`se-${eventId}`];
            let statusClass = 'border-bottom-dark';
            if (status === 'accepted') {
              statusClass = 'border-bottom-success';
            }
            if (status === 'rejected') {
              statusClass = 'border-bottom-danger';
            }
            return (
              <div key={eventId} className={`bx--tile border mt-2 p-4 ${statusClass}`}>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">Interview Name</div>
                  <div>:&nbsp;<span className="bx--type-strong">{'Face-to-Face interview'}</span></div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">{t('start')}</div>
                  <div>: { moment(start).utc()
                  // .add('480', 'minutes')
                  .format('DD MMM YYYY HH:mm') } (SGT)</div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">{t('end')}</div>
                  <div className="bx--type-caps">: { moment(end).utc()
                  // .add('480', 'minutes')
                  .format('DD MMM YYYY HH:mm') } (SGT)</div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">{t('status')}</div>
                  <div>:&nbsp;<span className="bx--tag bx--tag--custom bx--type-caps">{status || 'Pending'}</span></div>
                </div>
                <div className="bx--row">
                  <div className="bx--col-md-2 text-dark bx--type-strong">Info</div>
                  <div>: {description}</div>
                </div>
                <div className="text-dark bx--type-strong">{t('desc')}</div>
                <div>{ interviewDescription || 'N/A' }</div>
                {
                  !status && (
                    <Fragment>
                      <Button
                        kind="primary"
                        onClick={() => this.handleAcceptScheduledEvent(eventId)}
                        small
                        className="mt-2 mr-2"
                        disabled={rejectingEvent || acceptingEvent || deletingEvent}
                      >
                        { acceptingEvent ? 'Accepting...' : 'Accept' }
                      </Button>
                      <Button
                        kind="danger"
                        onClick={() => this.handleRejectScheduledEvent(eventId)}
                        small
                        className="mt-2 mr-2"
                        disabled={rejectingEvent || acceptingEvent || deletingEvent}
                      >
                        { rejectingEvent ? 'Rejecting...' : 'Reject' }
                      </Button>
                    </Fragment>
                  )
                }
                {
                  (slotType === 'fixed') && (
                    <Button
                      kind="danger"
                      onClick={() => this.handleDeleteScheduledEvent(eventId)}
                      small
                      className="mt-2 mr-2"
                      disabled={rejectingEvent || acceptingEvent || deletingEvent}
                    >
                      { deletingEvent ? 'Deleting...' : 'Delete' }
                    </Button>
                  )
                }
                {
                  status && (status === 'accepted') && (
                    <Button
                      kind="tertiary"
                      small
                      className="mt-2 mr-2"
                      onClick={() => this.openEmailModal(se)}
                      disabled={sendingEventEmail}
                    >
                      { sendingEventEmail ? 'Sending email...' : 'Send email' }
                    </Button>
                  )
                }
              </div>
            );
          })
        }
        {
          !loadingEvents && scheduledEvents && Array.isArray(scheduledEvents)
          && (scheduledEvents.length === 0) && (
            <div className="bx--tile">
              <div className="bx--type-gamma">{t('noInterviewSch')}</div>
            </div>
          )
        }
      </Fragment>
    );
  }

  closeEmailModal = () => {
    this.setState({
      isEmailModelOpen: false,
      selectedEvent: {},
    });
  }

  sendEmail = (selectedEmailTemplate) => {
    const { selectedEvent } = this.state;
    const { eventId, studentId } = selectedEvent || {};
    if (!eventId || !studentId || selectedEmailTemplate === null || selectedEmailTemplate.indexOf('placeholder-item') > -1) {
      this.setState({ noTemplateError: true });
      return;
    }
    const templateId = selectedEmailTemplate.split('_')[0];
    const templateName = selectedEmailTemplate.split('_')[1];
    const data = {
      templateId,
      templateName,
      payload: {
        eventId: eventId || '000',
        studentId: studentId || -1,
        islotApproveEmail: true,
      },
      actionName: 'send-email',
    };
    this.setState({
      selectedEmailTemplate: 'placeholder-item',
      selectedEmails: [],
      noTemplateError: false,
      selectedEvent: {},
      isEmailModelOpen: false,
      [`se-${eventId}`]: true,
    }, () => {
      this.props.sendEmails(data)
        .then((res) => {
          const { MessageId } = res || {};
          if (MessageId) {
            this.setState({
              isEmailModelOpen: false,
              selectedEvent: {},
              notifShow: true,
              notifType: 'success',
              notifMessage: 'Email sent to the student successfully.',
              [`se-${eventId}`]: false,
            });
          } else {
            this.setState({
              isEmailModelOpen: false,
              selectedEvent: {},
              notifShow: true,
              notifType: 'error',
              notifMessage: 'Error occured while sending email to the student.',
              [`se-${eventId}`]: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            isEmailModelOpen: false,
            selectedEvent: {},
            notifShow: true,
            notifType: 'error',
            notifMessage: 'Error occured while sending email to the student.',
            [`se-${eventId}`]: false,
          });
        });
    });
  }

  render() {
    const {
      notifType, notifMessage, notifShow,
      isEmailModelOpen,
    } = this.state;
    return (
      <Fragment>
        {
          notifShow && (
            <InlineNotification lowContrast
              kind={notifType}
              title={notifMessage}
              subtitle=""
              onCloseButtonClick={this.closeNotification}
            />
          )
        }
        <div className="mt-2">
          { this.renderScheduledInterviews() }
        </div>
        <EmailTemplatesModal
          isOpen={isEmailModelOpen}
          templateModalClose={this.closeEmailModal}
          templateModalSubmit={this.sendEmail}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  createInstitutionSlot,
  listInstitutionSlots,
  updateInstitutionSlot,
  deleteInstitutionSlot,
  createCalendarEvent,
  getAvailabilityElementToken,
  getStudentInterviewSlot,
  deleteCalendarEvent,
  rejectCalendarEvent,
  acceptCalendarEvent,
  sendEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ScheduledInterviews));
