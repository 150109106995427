import React from 'react';
import X0PALogo from 'assets/images/x0pa-purple.png';
import { useTranslation } from 'react-i18next';

const PublicFooter = (props) => {
  const { handleRedirectMainPage, currProd, hideTile } =
    props || {};
  const { t: translation } = useTranslation();
  return (
    <div style={{position: "absolute", bottom: "0"}} className={`PublicJobInfoFooter w-100 d-flex justify-content-between ${hideTile ? '' : 'bx--tile'}`}>
      <div
        className="pl-4"
        onClick={() => {
          handleRedirectMainPage(currProd || 'roboroy');
        }}
        href="javascript:void(0)"
        prefix="">
        <img className="xpa-top-header--logo-room" src={X0PALogo} alt="logo" />
      </div>
      <div className="mr-5" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="mr-2 pr-2">
          <a href="https://x0pa.com/terms-of-use/">
            {translation('terms-of-use')}
          </a>
        </div>
        <div className="ml-2">
          <a href="https://x0pa.com/privacypolicy/">
            {translation('privacy-policy')} 
          </a>
        </div>
        <div className="ml-2">
          <a href="https://x0pa.com/cookiespolicy/">
            {translation('cookies-policy')} 
          </a>
        </div>
      </div>
    </div>
  );
};

export default PublicFooter;